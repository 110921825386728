<template>
    <div>
      <agent-annuity-price-list></agent-annuity-price-list>
    </div>
  </template>
  
  <script>
  export default {
  }
  </script>
  